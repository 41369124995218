<template>
  <div class="diagnosis_box">
    <div class="diagnosis_left">
      <el-menu
        :default-active="cityActive"
        class="el-menu-vertical-demo">
        <el-submenu :index="item.ProvinceId+''" v-for="(item,s) in cityData" :key="s">
          <template slot="title">
            <span class="zm"></span>
            <span>{{item.ProvinceName}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item :index="item.ProvinceId+''" @click="citycheck(item.ProvinceId,item.ProvinceId,null)">
              <span class="zm"></span>
              <span>全省</span>
            </el-menu-item>
            <el-menu-item :index="it.CityId+''" v-for="(it,ind) in item.CityList" :key="ind" @click="citycheck(it.CityId,item.ProvinceId,it.CityId)">
              <span class="zm"></span>
              <span>{{it.CityName}}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
    <div class="diagnosis_right">
      <div class="diagnosis_list">
        <div class="item" @click="openDiagnosis(item)" v-for="(item, index) in mainList" :key="index">
          <div class="name">{{ item.DiagnosisName }}</div>
          <div class="year"><i class="iconfont">&#xe67f;</i>{{ item.DiagnosisYear }}年</div>
          <div class="subject"><i class="iconfont">&#xe630;</i>{{ item.SubjectTypeStr }}</div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="pages" @click.prevent="pagesbtn()">
          <i class="iconfont" v-if="mainList.length==0&&pageIndex==1">&#xe67c;</i>
          <span>{{pagetxt}}</span>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import API from '@/api/config';
import { getStore } from '@/utils/utils';
import { mapGetters } from 'vuex';
export default {
  name: "diagnosis",
  data(){
    return {
      cityData:[],
      cityActive:'',
      pageIndex:1,
      pagetxt:'数据查找中…',
      mainList: [],
      ProvinceId:null,
      CityId:null,
      HasNext:false
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  mounted(){
    this.initialize();
  },
  methods: {
    //省市切换
    citycheck(id,p,c){
      this.cityActive = id;
      this.pageIndex=1;
      this.ProvinceId = p;
      this.CityId = c;
      this.mainList = [];
      this.LineDiagnosisPaged();
    },
    // 打开对应诊断
    openDiagnosis(item){
      this.$router.push({
        path: '/diagnosis-info',
        query: {
          id: item.DiagnosisId,
          info: JSON.stringify(item)
        }
      })
    },

    // 初始化数据
    initialize(){
      this.LineDiagnosisArea();
    },
    //省市
    LineDiagnosisArea(){
      API.Public.LineDiagnosisArea().then(res => {
        if (res.code == 0) {
          this.cityData = res.data;
          //判断用户所属地区是否在获取的省份列表中
          let area = getStore('OrgProvince');
          if(area!=undefined&&area!=null&&area!=undefined&&area!=''&&this.cityData.find(f=>f.ProvinceId==JSON.parse(area).ProvinceId)!=undefined){
            this.cityActive = JSON.parse(area).ProvinceId;
            this.ProvinceId = JSON.parse(area).ProvinceId;
          }
          else{
            this.cityActive = res.data[0].ProvinceId;
            this.ProvinceId = res.data[0].ProvinceId;
          }
          this.LineDiagnosisPaged();
        }
      });
    },
    // 获取列表
    LineDiagnosisPaged(){
      API.Public.LineDiagnosisPaged(
        {
          "PageIndex": this.pageIndex,
          "PageSize": 10,
          "ProvinceId": this.ProvinceId,
          "CityId": this.CityId
        }
      ).then(res=>{
        if(res.data.Data.length>0){
          res.data.Data.forEach(f => {
            this.mainList.push(f);
          });
          this.HasNext=res.data.HasNext;
          if(this.HasNext){
            this.pagetxt='点击加载更多';
          }
          else{
            this.pagetxt='已经到底啦！';
          }
        }
        else{
          this.pagetxt='未找到信息！';
        }
      })
    },
    //加载更多
    pagesbtn(){
        if(this.HasNext){
            if(this.pagetxt=='点击加载更多'){
                this.pageIndex+=1;
                this.LineDiagnosisPaged();
            }
        }else{
          if(this.mainList.length!=0){
              this.pagetxt='已经到底啦！';
          }
        }
    }
  }
}
</script>

<style scoped lang="less">
.diagnosis_box{
  max-width: 1420px;
  margin: auto;
  .diagnosis_left{
    width: 200px;
    min-height: 600px;
    border: 1px solid #e2e2e2;
    float: left;
    .el-menu{
      border-right: none;
    }
    .zm{
      color: #666;
      font-size: 12px;
      margin-right: 10px;
    }
    // .el-submenu.is-active{
    //   background-color:rgba(var(--themecolor),0.75);
    // }
    
    .el-submenu.is-active .zm{
      color: #fff;
    }
    .el-menu-item{
      font-size: 14px;
    }
    .el-menu-item .zm{
      color: #666 !important;
    }
    .el-menu-item.is-active{
      color: #fff;
      background-color: rgba(var(--themecolor),0.75);
    }
    .el-menu-item:hover{
      background-color: rgba(var(--themecolor),0.2);
    }
    .el-menu-item.is-active:hover{
      color: #fff;
      background-color:rgba(var(--themecolor),0.75);
    }
    .el-menu-item.is-active .zm,.el-menu-item.is-active:hover .zm{
      color: #666;
    }
  }
  .diagnosis_right{
    width: calc(100% - 220px);
    border: 1px solid #e2e2e2;
    float: right;
  }
}
.diagnosis_list{
  margin: 20px 0px 0px;
  .item{
    float: left;
    width: calc(100% - 40px);
    margin: 0 20px 20px 20px;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #e2e2e2;
    float: right;
    .name{
      font-size: 22px;
      color: #444;
      width: calc(100% - 40px);
      padding: 10px 20px;
      text-align: center;
      line-height: 30px;
      background-color: rgba(var(--themecolor),0.1);
    }
    .year{
      width: 50%;
      color: #666;
      float: left;
      height: 60px;
      line-height: 60px;
      font-size: 18px;
      text-align: center;
      
    }
    .subject{
      width: 50%;
      color: #666;
      float: left;
      height: 60px;
      line-height: 60px;
      font-size: 18px;
      text-align: center;
    }
    i{
      margin-right: 10px;
      font-size: 14px;
      color: #9a9a9a;
    }
  }
  .item:hover{
    box-shadow: 2px 2px 15px 2px var(--boxShadow);
  }
  .item:hover .name{
    background-color: rgba(var(--themecolor),0.2);
  }
  .have-no-data{
    width: 100%;
    padding-top: 80px;
    text-align: center;
    color: #9a9a9a;
    font-size: 24px;
  }
}
.pages{
    width: 100%;
    min-height: 50px;
    line-height: 50px;
    color: #666;
    font-size: 16px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 10px;
    user-select: none;
    .iconfont{
        font-size: 50px;
        display: block;
        margin-top: 40px;
        color: #9b9b9b;
    }
}
.pages:hover{
    color: rgb(var(--themecolor));
    cursor: pointer;
}
</style>
<style lang="less">
.diagnosis_box .el-menu-item-group__title{
      padding: 0;
    }
// .diagnosis_box .el-submenu.is-active .el-submenu__title{
//   color: #fff !important;
// }
// .diagnosis_box .el-submenu.is-active .el-submenu__title:hover{
//   background-color:rgba(var(--themecolor),0.7);
// }
.diagnosis_box .el-submenu .el-submenu__title:hover{
  background-color:rgba(var(--themecolor),0.1);
}
</style>